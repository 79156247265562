export class SalaryHead {
    constructor() {
        this.name = null;
        this.type = null;
    }
    public name: any;
    public type: any;
}

export class DetailArray {
    constructor() {
        this.isApplicable = true;
        this.salaryHeadId = null;
        this.salaryPercent = null;
        this.onSalaryHeadId = null;
        this.salaryHeadType = null
        this.amount = null;
    }

    public isApplicable: Boolean;
    public salaryHeadId: any;
    public salaryHeadType: any
    public salaryPercent: any;
    public onSalaryHeadId: any;
    public amount: any;

}

export class SalaryStructureData {
    constructor() {
        this.empUserId = null;
        this.salaryType = null;
        this.totalSalary = null;
        this.earning = new Array<DetailArray>();
        this.totalEarning = 0;
        this.deduction = new Array<DetailArray>();
        this.totalDeduction = 0;
        this.netPayableAmount = 0;
    }
    public empUserId: any;
    public salaryType: any;
    public totalSalary: any;
    public earning: Array<DetailArray>;
    public totalEarning: any;
    public deduction: Array<DetailArray>;
    public totalDeduction: any;
    public netPayableAmount: any;
}
export class Setting {
    constructor() {
        this.salaryDeductionTime = null;
        this.basedOn = null;
    }
    public salaryDeductionTime: any;
    public basedOn: any;
}
export class SalarySlipData {
    constructor() {
        this.empUserId = null;
        this.year = null;
        this.month = null;
    }
    public empUserId: any;
    public year: any;
    public month: any;
}