

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import { SalaryHead } from "../../../models/sub-domain/salary-head.model";
import { Setting } from "../../../models/sub-domain/salary-head.model";

@Component
export default class PayrollSetting extends Vue {
	public payrollSetting = new Setting();
	public salaryHeadList = [];
	public earningSalaryHeadList: any = [];
	public inProcess = false;

	public async fetchSalaryHeadList() {
		try {
			let response = await Axios.get(
				BASE_API_URL + "payroll/fetchSalaryHeadList",
				{
					headers: authHeader()
				}
			);
			this.salaryHeadList = response.data ? response.data : [];
			this.salaryHeadList.forEach((element: any) => {
				if (element.name == "Total Salary" || element.type == "Earning") {
					var Obj: any = {
						id: element._id,
						name: element.name
					};
					this.earningSalaryHeadList.push(Obj);
				}
			});
		} catch (error) {
			console.log(error);
		}
	}

	public async validateAndSave() {
		try {
			let response = await Axios.post(
				BASE_API_URL + "payroll/savePayrollSetting",
				this.payrollSetting,
				{
					headers: authHeader()
				}
			);
			if (response.data) this.$snotify.success(response.data.message);
			this.$router.push('payroll');
			this.fetchPayrollSetting();
		} catch (error) {
			console.log(error);
		}
	}

	public async fetchPayrollSetting() {
		try {
			let response = await Axios.get(
				BASE_API_URL + "payroll/fetchPayrollSetting",
				{
					headers: authHeader()
				}
			);
			this.payrollSetting = response.data[0] ? response.data[0] : [];
		} catch (error) {
			console.log(error);
		}
	}

	async mounted() {
		await this.fetchSalaryHeadList();
		await this.fetchPayrollSetting();
	}
}
